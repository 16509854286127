import { VENDORS, VENDORS_SHOW, VENDORS_TRANSITIONS } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        console.log(query);
        let url = new URL(VENDORS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(vendor = null) {
        if (vendor === null) return;

        const url = VENDORS_SHOW.replace(":vendor", vendor);

        return api.get(url);
    },

    update(vendor, data) {
        if (vendor === null) return;

        const url = VENDORS_TRANSITIONS.replace(":vendor", vendor);

        return api.put(url, data);
    }
}