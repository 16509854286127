<template>
  <app-layout>
    <template v-slot:header> Vanzatori </template>

    <page-section>
      <page-section-body>
        <form class="flex" @submit.prevent="index">
          <div class="sm:w-40 mr-2 ml-0">
            <v-input type="text" v-model="query.name" placeholder="Nume" />
          </div>
          <div class="sm:w-40 mr-2">
            <v-input
              type="text"
              v-model="query.fiscal_code"
              placeholder="Cod fiscal"
            />
          </div>
          <div class="sm:w-40 mr-2">
            <v-input
              type="text"
              v-model="query.registration_number"
              placeholder="Nr. inregistrare"
            />
          </div>
          <v-button class="self-end"> Filtreaza </v-button>
        </form>
        <v-card class="mt-2">
          <loader v-if="loading" class="my-5" />

          <template v-else>
            <template v-if="totalRows">
              <vendors-index-table :rows="rows"></vendors-index-table>
              <pagination
                @change:page="changePage($event)"
                :total="meta.total"
                :current_page="meta.current_page"
                :from="meta.from"
                :to="meta.to"
                :next_page_url="links.next"
                :prev_page_url="links.prev"
              />
            </template>
            <no-results v-else class="m-5" />
          </template>
        </v-card>
      </page-section-body>
    </page-section>
  </app-layout>
</template>

<script>
import vendorsApi from "../api/resources/vendorsApi";

import VendorsIndexTable from "../components/VendorsIndexTable.vue";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import VInput from "../components/ui/VInput.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionBody from "../components/ui/PageSectionBody.vue";
import VButton from "../components/ui/VButton.vue";

export default {
  components: {
    VendorsIndexTable,
    AppLayout,
    VCard,
    Loader,
    Pagination,
    NoResults,
    VInput,
    PageSection,
    PageSectionBody,
    VButton,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      query: {
        name: "",
        fiscal_code: "",
        registration_number: "",
        page: 1,
      },
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    changePage(page) {
      this.query.page = page;
      this.index();
    },

    index() {
      this.loading = true;
      vendorsApi
        .index({ ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
